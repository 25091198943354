import { put, select, takeLatest } from 'redux-saga/effects';
import {
  LOAD_WP_POST, loadWpPost, loadWpPostFail, loadWpPostSuccess,
  LOAD_RELATED_POST, loadRelatedPost, loadRelatedPostFail, loadRelatedPostSuccess,
} from './actions';
import { selectWpPostEntity } from './selectors';
import wpApi from '../../utils/wpApi';
import Post from '../../models/Post';

export function shouldFetchPost(state) {
  const isLoading = state.get('loading');
  const isExist = state.get('item');
  return !isLoading && !isExist;
}

export function* loadWpPostRequest(action) {
  const wpState = yield select(selectWpPostEntity(action.postId));
  if (wpState && !shouldFetchPost(wpState)) {
    return;
  }
  // FIXME Can we update this to use the slug?
  yield put(loadWpPost(action.postId));
  try {
    const response = yield wpApi.posts().id(action.postId).embed();
    const post = new Post(response);
    console.log('Raw post details');
    console.log(response);
    yield put(loadWpPostSuccess(action.postId, post));
  } catch (error) {
    yield put(loadWpPostFail(action.postId, error));
  }
}

export function* loadRelatedPostRequest(action) {
  const { id, date, categories } = action.post;
  yield put(loadRelatedPost(id));
  try {
    const response = yield wpApi.posts()
      .categories(categories)
      .before(date)
      .page(1)
      .perPage(10)
      .embed();
    const posts = response.map((item) => new Post(item));
    yield put(loadRelatedPostSuccess(id, posts));
  } catch (error) {
    yield put(loadRelatedPostFail(id, error));
  }
}

export function* latestPostWatcher() {
  yield takeLatest(LOAD_WP_POST.REQUEST, loadWpPostRequest);
}

export function* relatedPostWatcher() {
  yield takeLatest(LOAD_RELATED_POST.REQUEST, loadRelatedPostRequest);
}

// All sagas to be loaded
export default [
  latestPostWatcher,
  relatedPostWatcher,
];
