import { fromJS, Set } from 'immutable';
import { LOAD_WP_COMMENTS } from './actions';

const commentsState = fromJS({
  loading: false,
  errorMessage: '',
  comments: Set(),
});
export function commentsReducer(state = commentsState, action) {
  switch (action.type) {
    case LOAD_WP_COMMENTS.FETCH: {
      return state
        .set('loading', true);
    }

    case LOAD_WP_COMMENTS.SUCCESS: {
      const { comments } = action;
      // const commentIds = comments.map((c) => c.id);
      return state
        .set('loading', false)
        .set('comments', comments);
        // .update('comments', (set) => set.union(commentIds));
    }

    case LOAD_WP_COMMENTS.FAILURE: {
      return state
        .set('loading', false)
        .set('errorMessage', action.error.message);
    }

    case LOAD_WP_COMMENTS.CANCEL: {
      return state
        .set('loading', false);
    }

    default:
      return state;
  }
}

const initialState = fromJS({});
export default function commentsMappingReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_WP_COMMENTS.FETCH:
    case LOAD_WP_COMMENTS.SUCCESS:
    case LOAD_WP_COMMENTS.FAILURE: {
      const selectedList = state.get(action.postId);
      const updatedList = commentsReducer(selectedList, action);
      return state.set(action.postId, updatedList);
    }

    default:
      return state;
  }
}
