import { LOAD_WP_POSTS } from '../WpPostList/actions';
import { LOAD_WP_POST, LOAD_RELATED_POST } from './actions';
import { fromJS } from 'immutable';

const entitiesState = fromJS({});
export default function entitiesReducer(state = entitiesState, action) {
  switch (action.type) {
    case LOAD_WP_POSTS.SUCCESS: {
      const { posts } = action;
      return posts.reduce((lastState, post) => (
        lastState.set(post.id, fromJS({
          loading: false,
          errorMessage: '',
          item: post,
          related: [],
        }))
      ), state);
    }

    case LOAD_WP_POST.FETCH:
    case LOAD_WP_POST.SUCCESS:
    case LOAD_WP_POST.FAILURE:
    case LOAD_RELATED_POST.FETCH:
    case LOAD_RELATED_POST.SUCCESS:
    case LOAD_RELATED_POST.FAILURE: {
      const selectedEntity = state.get(action.postId);
      const newState = entityReducer(selectedEntity, action);
      return state.set(action.postId, newState);
    }

    default:
      return state;
  }
}

const entityState = fromJS({
  loading: true,
  errorMessage: '',
  item: undefined,
  related: [],
});
export function entityReducer(state = entityState, action) {
  switch (action.type) {
    case LOAD_WP_POST.FETCH: {
      return state
        .set('loading', true)
        .set('errorMessage', '');
    }

    case LOAD_WP_POST.SUCCESS: {
      console.log('Loaded post success!');
      return state
        .set('loading', false)
        .set('errorMessage', '')
        .set('item', action.post);
    }

    case LOAD_WP_POST.FAILURE: {
      console.log('Loaded post error!');
      return state
        .set('loading', false)
        .set('errorMessage', action.error.message);
    }

    case LOAD_RELATED_POST.SUCCESS: {
      console.log('Loaded related success!');
      return state
        .set('related', fromJS(action.posts));
    }

    default:
      return state;
  }
}
