import { combineReducers } from 'redux-immutable';
import Omit from 'object.omit';
import { fromJS } from 'immutable';
import { getUID } from '../utils';
import defaultSettings from '../settings';
import {
  UPDATE_SETTINGS,
  ADD_TOAST,
  REMOVE_TOAST,
  SET_SEARCH_ACTION,
  SET_SHOP_STATE,
  RESET_SEARCH_ACTION,
} from '../actions';

import authReducer from '../pages/Auth/reducer';
import globalReducer from '../pages/App/reducer';

import searchReducer from '../pages/Search/reducer';

import eventReducer from '../pages/Events/reducer';
import chatReducer from '../pages/VirtualLounge/reducer';
import { Transducer } from '../utils/transducer';
// import { TOGGLE_SIDE_BAR } from '../pages/App/actions';

// initial state.
// TODO Set the cart in local storage so it can be retrieved here
// const INITIAL_SHOP_STATE = fromJS(window.Snipcart ? window.Snipcart.store.getState() : {});
const INITIAL_SHOP_STATE = fromJS({
  cart: Transducer.getPreference('cart') || {}, // TODO Create a cart for the user right here if none exists?
});
const INITIAL_SETTINGS_STATE = {
  ...defaultSettings,
};
const INITIAL_TOASTS_STATE = [];
const INITIAL_ACTIONS_STATE = fromJS({
  route: '/cigars',
  data: {},
});

/**
 * Reducer
 */
const rootReducer = combineReducers({
  auth: authReducer,
  // INFO This is handled using the PullState store now
  // showMenu: (state = false, action) => {
  //   switch (action.type) {
  //     case TOGGLE_SIDE_BAR:
  //       return !state;
  //     default:
  //       return state;
  //   }
  // },
  settings: (state = INITIAL_SETTINGS_STATE, action) => {
    switch (action.type) {
      case UPDATE_SETTINGS:
        console.log(action.settings);
        return { ...state, ...action.settings };
      default:
        return state;
    }
  },
  toasts: (state = INITIAL_TOASTS_STATE, action) => {
    switch (action.type) {
      case ADD_TOAST:
        const newData = {
          ...{
            title: '',
            content: '',
            color: 'brand',
            time: false,
            duration: 0,
            closeButton: true,
          },
          ...action.data,
        };

        if (newData.time === true) {
          newData.time = new Date();
        }

        console.log(newData);

        return {
          ...state,
          [getUID()]: newData,
        };
      case REMOVE_TOAST:
        if (!action.id || !state[action.id]) {
          return state;
        }
        return Omit(state, action.id);
      default:
        return state;
    }
  },
  actions: (state = INITIAL_ACTIONS_STATE, action) => {
    switch (action.type) {
      case SET_SEARCH_ACTION:
        // console.log('Search action redux selection:');
        // console.log(action);
        return state.set('search', { ...state, ...action.data });
      case RESET_SEARCH_ACTION:
        // console.log('Resetting search action redux selection:');
        // console.log(action);
        return fromJS({
          route: '/cigars',
          data: {},
        });
      default:
        return state;
    }
  },
  shop: (state = INITIAL_SHOP_STATE, action) => {
    switch (action.type) {
      case SET_SHOP_STATE:
        console.log('Shop state redux action:');
        console.log(action);
        const cart = { ...state, ...action.data };
        console.log(cart);
        Transducer.setPreference('cart', cart);
        return state.set('cart', cart);
      default:
        return state;
    }
  },
  global: globalReducer,
  search: searchReducer,
  events: eventReducer, // FIXME Do we still need events? Should they be separate views? Maybe the user's saved events / calendar?
  chat: chatReducer, // FIXME Hook this up to Mesibo? Or just have the links in this app pass data to the Lounge service?
});

export default rootReducer;
