import { createSelector } from 'reselect';
import { fromJS, Set } from 'immutable';
import { selectWpPostEntities } from '../WpPostDetail/selectors';

export const selectWpPostListMapping = () => (state) => state.getIn(['global', 'wpPost', 'list']);
export const selectWpPostList = (name) => createSelector(
  selectWpPostListMapping(),
  (wpState) => wpState.get(name, fromJS({
    currentPage: 0,
    totalPages: 999,
    loading: false,
    errorMessage: '',
    posts: Set(),
  }))
);

export const selectWpLoading = (name) => createSelector(
  selectWpPostList(name),
  (wpState) => wpState.get('loading'),
);

export const selectWpErrorMessage = (name) => createSelector(
  selectWpPostList(name),
  (wpState) => wpState.get('errorMessage'),
);

export const selectWpPostCurrentPage = (name) => createSelector(
  selectWpPostList(name),
  (wpState) => wpState.get('currentPage'),
);

export const selectWpPostTotalPage = (name) => createSelector(
  selectWpPostList(name),
  (wpState) => wpState.get('totalPages'),
);

export const selectWpPostHasMore = (name) => createSelector(
  selectWpPostCurrentPage(name),
  selectWpPostTotalPage(name),
  (currentPage, totalPage) => (currentPage < totalPage),
);

export const selectWpPostListEntities = (name) => createSelector(
  selectWpPostList(name),
  selectWpPostEntities(),
  (wpState, entities) => {
    const postIds = wpState.get('posts');
    console.log(postIds);
    console.log(entities);
    // console.log(JSON.stringify(entities));
    return postIds.map((id) => entities.getIn([id, 'item'])).sort((a, b) => b.id - a.id);
  },
);
