import { createSelector } from 'reselect';
import { fromJS } from 'immutable';

export const selectWpPostEntities = () => (state) => state.getIn(['global', 'wpPost', 'entities']);

export const selectWpPostEntity = (postId) => createSelector(
  selectWpPostEntities(),
  (entities) => entities.get(postId, fromJS({
    loading: false,
    errorMessage: '',
    item: undefined,
    related: [],
  }))
);

export const selectWpPostLoadingById = (postId) => createSelector(
  selectWpPostEntity(postId),
  (entity) => entity.get('loading'),
);

export const selectWpPostErrorMessageById = (postId) => createSelector(
  selectWpPostEntity(postId),
  (entity) => entity.get('errorMessage'),
);

export const selectWpPostById = (postId) => createSelector(
  selectWpPostEntity(postId),
  (entity) => entity.get('item'),
);

export const selectRelatedPostById = (postId) => createSelector(
  selectWpPostEntity(postId),
  (entity) => entity.get('related'),
);
