import { put, select, takeLatest } from 'redux-saga/effects';
import { selectWpCommentEntities } from './selectors';
import {
  LOAD_WP_COMMENTS,
  loadWpComments,
  loadWpCommentsSuccess,
  loadWpCommentsFail,
} from './actions';
import wpApi from '../../utils/wpApi';
import Comment from '../../models/Comment';

export function shouldFetchComments(state) {
  const isLoading = state.get('loading');
  const isExist = state.get('comments');
  return !isLoading && !isExist;
}

export function* loadWpCommentsRequest(action) {
  const wpState = yield select(selectWpCommentEntities(action.postId));
  if (wpState && !shouldFetchComments(wpState)) {
    console.log('No need to fetch comments');
    return;
  }
  yield put(loadWpComments(action.postId));
  try {
    const response = yield wpApi.comments().forPost(action.postId).embed();
    console.log('Comments response:');
    console.log(response);
    const comments = response.map((item) => new Comment(item));
    console.log(comments);
    console.log('Post ID:');
    console.log(action.postId);
    yield put(loadWpCommentsSuccess(action.postId, comments));
  } catch (error) {
    console.log('Unable to get comments:');
    console.log(error);
    yield put(loadWpCommentsFail(action.postId, error));
  }
}

export function* loadWpCommentsWatcher() {
  yield takeLatest(LOAD_WP_COMMENTS.REQUEST, loadWpCommentsRequest);
}

export default [
  loadWpCommentsWatcher,
];
