import { fromJS, Set } from 'immutable';
import { LOAD_WP_POSTS } from './actions';
import { LOCATION_CHANGE } from 'react-router-redux';

export const listState = fromJS({
  currentPage: 0,
  totalPages: 999,
  loading: false,
  errorMessage: '',
  posts: Set(),
});
export function listReducer(state = listState, action) {
  switch (action.type) {
    case LOAD_WP_POSTS.FETCH: {
      return state
        .set('currentPage', action.page)
        .set('loading', true);
    }

    case LOAD_WP_POSTS.SUCCESS: {
      const { paging, posts } = action;
      const postIds = posts.map((p) => p.id);
      return state
        .set('totalPages', parseInt(paging.totalPages, 10))
        .set('loading', false)
        .update('posts', (set) => set.union(postIds));
    }

    case LOAD_WP_POSTS.FAILURE: {
      return state
        .set('loading', false)
        .set('errorMessage', action.error.message);
    }

    case LOAD_WP_POSTS.CANCEL: {
      return state
        .set('loading', false);
    }

    default:
      return state;
  }
}

export const initialState = fromJS({});
export default function listMappingReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_WP_POSTS.FETCH:
    case LOAD_WP_POSTS.SUCCESS:
    case LOAD_WP_POSTS.FAILURE:
    case LOAD_WP_POSTS.CANCEL: {
      const selectedList = state.get(action.name); // FIXME This line makes it mutable
      const updatedList = listReducer(selectedList, action);
      return state.set(action.name, updatedList);
    }

    case LOCATION_CHANGE: {
      return state.set('search', listState);
    }

    default:
      return state;
  }
}
