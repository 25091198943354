import { put, select, throttle } from 'redux-saga/effects';
import wpApi from '../../utils/wpApi';
import { LOAD_WP_POSTS, loadWpPosts, loadWpPostsSuccess, loadWpPostsFail } from './actions';
import Post from '../../models/Post';
import { selectWpLoading } from './selectors';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

function* addFilter(request, key, value) {
  switch (key) {
    case 'category': {
      if (value === 'all') return request;
      const categories = yield wpApi.categories().slug(value);
      return request.categories(categories.map((cat) => cat.id));
    }

    case 'categories_exclude': {
      if (value === 'all') return request;
      const categories = yield wpApi.categories().slug(value);
      return request.excludeCategories(categories.map((cat) => cat.id));
    }

    case 'tag': {
      const tags = yield wpApi.tags().slug(value);
      return request.tags(tags.map((tag) => tag.id));
    }

    case 'search': {
      return request.search(value);
    }

    default:
      return request;
  }
}

export function* loadWpPostsRequest(action) {
  const { name, page, options } = action;

  const isLoading = yield select(selectWpLoading(name));
  if (isLoading) {
    return;
  }

  yield put(loadWpPosts(name, page));
  try {
    let request = wpApi.posts();
    const keys = Object.keys(options);
    for (let i = 0; i < keys.length; i++) { // eslint-disable-line
      const filterKey = keys[i];
      const value = options[filterKey];
      request = yield addFilter(request, filterKey, value);
    }

    const response = yield request
      .embed()
      .perPage(config.pagingItem)
      .page(page);

    let pagingInformation;
    if (response._paging) { // eslint-disable-line
      console.log('Paging response from WP'); // eslint-disable-line
      console.log(response._paging); // eslint-disable-line
      pagingInformation = response._paging; // eslint-disable-line
      delete response._paging; // eslint-disable-line
    } else {
      pagingInformation = { totalPages: 1 };
    }
    const posts = response.map((item) => new Post(item));
    yield put(loadWpPostsSuccess(name, pagingInformation, posts));
  } catch (error) {
    yield put(loadWpPostsFail(name, error));
  }
}

export function* loadWpPostsWatcher() {
  yield throttle(500, LOAD_WP_POSTS.REQUEST, loadWpPostsRequest);
}

export default [
  loadWpPostsWatcher,
];
