import { createSelector } from 'reselect';
import { fromJS, Set } from 'immutable';

// INFO This 'global' object is mapped out in App/reducer.js
export const selectWpPostListMapping = () => (state) => state.getIn(['global', 'wpPost', 'comments']);
console.log(selectWpPostListMapping());
export const selectWpComments = (postId) => createSelector(
  selectWpPostListMapping(),
  (wpState) => wpState.get(postId, fromJS({
    loading: false,
    errorMessage: '',
    comments: Set(),
  }))
);

export const selectWpLoading = (postId) => createSelector(
  selectWpComments(postId),
  (wpState) => wpState.get('loading'),
);

export const selectWpErrorMessage = (postId) => createSelector(
  selectWpComments(postId),
  (wpState) => wpState.get('errorMessage'),
);

export const selectWpCommentEntities = (postId) => createSelector(
  selectWpComments(postId),
  (wpState) => wpState.get('comments'),
);

// export const selectWpCommentEntities = (name) => createSelector(
//   selectWpPostList(name),
//   selectWpPostEntities(),
//   (wpState, entities) => {
//     const postIds = wpState.get('comments');
//     return postIds.map((id) => entities.getIn([id, 'item']))
//       .sort((a, b) => b.id - a.id);
//   },
// );
