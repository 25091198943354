import React from 'react';
import { language, messages } from './utils/localeUtils';
import { Transducer } from './utils/transducer';

const env = process.env.NODE_ENV || 'development';
export const config = require('../config/config.json')[env];
export const apiKeys = require('../config/apiKeys.json')[env];

/**
 * App Settings
 */
const settings = {
  theme: Transducer.getPreference('theme') || 'System', // 'System', 'Light', or 'Dark
  night_mode: Transducer.getPreference('dark_mode') === 'true',
  show_create_humidor_card: Transducer.getPreference('show_create_humidor_card') === 'true',
  spotlight_mode: false,
  show_section_lines: false,
  sidebar_dark: false,
  sidebar_static: false,
  sidebar_small: false,
  sidebar_effect: 'shrink',
  nav: true,
  nav_dark: false,
  nav_logo_path: require('../common-assets/images/logo.png'),
  nav_logo_path_small: require('../common-assets/images/logo_small.png'),
  // nav_logo_white_path: require('../common-assets/images/logo-white.svg'),
  nav_logo_white_path: require('../common-assets/images/logo.png'),
  nav_logo_width: '125px',
  nav_logo_url: '/nearby',
  nav_align: 'left',
  nav_expand: 'lg',
  nav_sticky: true,
  nav_autohide: true,
  nav_container_fluid: true,
  app_start_screen: Transducer.getPreference('start-screen') || 'Nearby',
  auto_post_social_networks: Transducer.getPreference('auto_post_social_networks') === 'true',
  home_url: '/',
  navigation: {
    '#actions': {
      name: 'Quick Actions',
      chips: [
        {
          name: messages[language]?.smoke_now || 'Smoke Now',
          icon: 'edit',
          route: 'cigars/:uid',
          params: '?action=smokenow',
          results: 'cigars',
          action: (callback) => {
            console.log('Clicked smoke now btn');
            // TODO
            //  1. Set a global field in the store (useContext? or similar?)
            //  2. Callback or something that handles opening the search results through the navbar
            //  3. Search on click needs to use the global field to route to the correct view (override default)
            callback();
          },
        },
        {
          name: messages[language]?.add_to_humidor || 'Add To Humidor',
          icon: 'plus-circle',
          route: 'profile/humidors',
          params: '?action=add',
          results: 'cigars',
          action: (callback) => {
            console.log('Clicked add to humidor btn');
            callback();
          },
        },
        {
          name: messages[language]?.checkin || 'Check In',
          icon: 'map-pin',
          route: 'venues/:uid',
          params: '?action=checkin',
          results: 'venues',
          action: (callback) => {
            console.log('Clicked check in btn');
            callback();
          },
        },
        {
          name: messages[language]?.add_friends || 'Add Friends',
          icon: 'users',
          route: 'users/:uid',
          params: '?action=add',
          results: 'users',
          action: (callback) => {
            console.log('Clicked add friends btn');
            callback();
          },
        },
        // {
        //   name: messages[language]?.review_product || 'Review Product',
        //   icon: 'star',
        //   route: 'products/:uid',
        //   params: '?action=review',
        //   results: 'products',
        //   action: (callback) => {
        //     console.log('Clicked review product btn');
        //     callback();
        //   },
        // },
      ],
    },
  },
  navigation_sidebar: [
    {
      name: messages[language]?.near_me || 'Near Me',
    },
    {
      name: messages[language]?.lounges_shops || 'Lounges & Shops',
      icon: 'map-pin',
      path: '/nearby',
    },
    {
      name: messages[language]?.upcoming_events || 'Upcoming Events',
      icon: 'calendar',
      path: '/events',
    },
    {
      name: messages[language]?.cigars || 'Cigars',
    },
    // '/profile': {
    //     name: 'Profile',
    //     icon: 'user',
    // },
    // '/mailbox': {
    //     name: 'Mailbox',
    //     icon: 'send',
    // },
    // '/messenger': {
    //     name: 'Messenger',
    //     icon: 'message-circle',
    // },
    // '/events': {
    //     name: 'Events',
    //     icon: 'calendar',
    // },
    {
      name: messages[language]?.virtual_humidor || 'Virtual Humidor',
      icon: 'inbox', // TODO Custom SVG
      path: '/profile/humidors',
    },
    {
      name: messages[language]?.cigar_discovery || 'Explore',
      icon: 'compass',
      path: '/explorer',
    },
    {
      name: messages[language]?.articles || 'Articles',
      icon: 'book-open',
      path: '/articles',
    },
    {
      name: 'Social',
    },
    {
      name: messages[language]?.feed || 'Feed',
      icon: 'rss',
      path: '/',
    },
    {
      name: messages[language]?.friends_list || 'Friends List',
      icon: 'user-plus',
      path: '/profile/friends',
    },
    // {
    //   name: 'Trending',
    //   icon: 'trending-up',
    //   path: '/top-rated',
    // },
    // {
    //   name: 'Virtual Lounge',
    //   icon: 'video',
    //   path: `https://${config.loungeUrl}`,
    // },
    {
      name: messages[language]?.groups || 'Groups',
      icon: 'users',
      path: '/groups',
    },
    {
      name: messages[language]?.saved_items || 'Saved Items',
      icon: 'bookmark',
      path: '/saved',
    },
    {
      name: messages[language]?.support || 'Help & Support',
    },
    // {
    //   name: messages[language]?.get_help || 'Get Help',
    //   icon: 'none',
    //   path: 'https://help.boxpressd.com/',
    // },
    {
      name: messages[language]?.request_feature || 'Request Feature',
      icon: 'tool',
      path: 'https://feedback.boxpressd.com/',
    },
    {
      name: messages[language]?.contact || 'Contact',
      icon: 'message-circle',
      path: 'https://lp.cigarsnearme.com/contact',
    },
  ],
  breadcrumbs_presets: {
    apps: {
      '/mailbox': 'Mailbox',
      '/messenger': 'Messenger',
      '/calendar': 'Calendar',
      '/project-management': 'Project Management',
      '/file-manager': 'File Manager',
      '/profile': 'Profile',
    },
    content: {
      '/grid': 'Grid',
      '/colors': 'Colors',
      '/typography': 'Typography',
      '/component-table': 'Tables',
    },
    components_base: {
      '/component-alert': 'Alert',
      '/component-badge': 'Badge',
      '/component-breadcrumbs': 'Breadcrumbs',
      '/component-button': 'Button',
      '/component-card': 'Card',
      '/component-carousel': 'Carousel',
      '/component-collapse': 'Collapse',
      '/component-dropdown': 'Dropdown',
      '/component-list-group': 'List Group',
      '/component-media-object': 'Media Object',
      '/component-modal': 'Modal',
      '/component-nav': 'Nav',
      '/component-pagination': 'Pagination',
      '/component-popover': 'Popover',
      '/component-progress': 'Progress',
      '/component-spinner': 'Spinner',
      '/component-tabs': 'Tabs',
    },
    components_advanced: {
      '/component-icons-feather': 'Icons: Feather',
      '/component-icons-fontawesome': 'Icons: Font Awesome',
      '/component-charts-chartjs': 'Charts: Chart.js',
      '/component-charts-chartist': 'Charts: Chartist',
      '/component-charts-peity': 'Charts: Peity',
      '/component-charts-echarts': 'Charts: eCharts',
      '/component-charts-flot': 'Charts: Flot',
      '/component-timeline': 'Timeline',
      '/component-tree-view': 'Tree View',
      '/component-toast': 'Toast',
      '/component-sweet-alert': 'Sweet Alert',
      '/component-maps': 'Maps',
      '/component-data-tables': 'Data Tables',
    },
    forms_advanced: {
      '/forms-datetime': 'DateTime Picker',
      '/forms-validation': 'Validation',
      '/forms-touch-spin': 'Touch Spin',
      '/forms-range-slider': 'Range Slider',
      '/forms-input-masks': 'Input Masks',
      '/forms-dropzone': 'Dropzone',
      '/forms-colorpicker': 'Color Picker',
      '/forms-select': 'Select',
      '/forms-markdown': 'Markdown editor',
      '/forms-wysiwyg': 'WYSIWYG editor',
    },
  },
  img_country: {
    usa: require('../common-assets/images/flags/united-states-of-america.svg'),
    china: require('../common-assets/images/flags/china.svg'),
    germany: require('../common-assets/images/flags/germany.svg'),
    japan: require('../common-assets/images/flags/japan.svg'),
    spain: require('../common-assets/images/flags/spain.svg'),
    france: require('../common-assets/images/flags/france.svg'),
    canada: require('../common-assets/images/flags/canada.svg'),
    netherlands: require('../common-assets/images/flags/netherlands.svg'),
    italy: require('../common-assets/images/flags/italy.svg'),
    russia: require('../common-assets/images/flags/russia.svg'),
    czech_republic: require('../common-assets/images/flags/czech-republic.svg'),
  },
  img_file: {
    empty: require('../common-assets/images/icon-empty.svg'),
    zip: require('../common-assets/images/icon-zip.svg'),
    rar: require('../common-assets/images/icon-rar.svg'),
    html: require('../common-assets/images/icon-html.svg'),
    php: require('../common-assets/images/icon-php.svg'),
    css: require('../common-assets/images/icon-css.svg'),
    js: require('../common-assets/images/icon-js.svg'),
    doc: require('../common-assets/images/icon-doc.svg'),
    txt: require('../common-assets/images/icon-txt.svg'),
    pdf: require('../common-assets/images/icon-pdf.svg'),
    xls: require('../common-assets/images/icon-xls.svg'),
    png: require('../common-assets/images/icon-png.svg'),
    jpg: require('../common-assets/images/icon-jpg.svg'),
  },
  users: [
    {
      img: require('../common-assets/images/avatar-1.png'),
      img_profile: require('../common-assets/images/avatar-1-profile.png'),
      name: 'Jack Boyd',
    }, {
      img: require('../common-assets/images/avatar-2.png'),
      name: 'Helen Holt',
    }, {
      img: require('../common-assets/images/avatar-3.png'),
      name: 'Avice Harris',
    }, {
      img: require('../common-assets/images/avatar-4.png'),
      name: 'Anna Rice',
    }, {
      img: require('../common-assets/images/avatar-5.png'),
      name: 'Amber Smith',
    }, {
      img: require('../common-assets/images/avatar-6.png'),
      name: 'Mary Rose',
    },
  ],
  letters: [
    {
      img: require('../common-assets/images/letter-1.png'),
      img_min: require('../common-assets/images/letter-1-min.png'),
    }, {
      img: require('../common-assets/images/letter-2.png'),
      img_min: require('../common-assets/images/letter-2-min.png'),
    }, {
      img: require('../common-assets/images/letter-3.png'),
      img_min: require('../common-assets/images/letter-3-min.png'),
    }, {
      img: require('../common-assets/images/letter-4.png'),
      img_min: require('../common-assets/images/letter-4-min.png'),
    },
  ],
};

/* Parse GET variables to change initial settings */
const $_GET = {};
window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (a, name, value) => {
  $_GET[name] = value;
});

Object.keys($_GET).forEach((name) => {
  const isTrue = $_GET[name] === '1';

  switch (name) {
    case 'setting-night-mode':
      settings.night_mode = isTrue;
      break;
    case 'setting-show-section-lines':
      settings.show_section_lines = isTrue;
      break;
    case 'setting-sidebar-small':
      settings.sidebar_small = isTrue;
      break;
    case 'setting-sidebar-dark':
      settings.sidebar_dark = isTrue;
      break;
    case 'setting-nav-dark':
      settings.nav_dark = isTrue;
      break;
    // no default
  }
});

export default settings;
