import { createAsyncAction } from '../../utils/actionUtils';

export const LOAD_WP_COMMENTS = createAsyncAction('LOAD_WP_COMMENTS');

export function requestWpComments(postId) {
  return {
    type: LOAD_WP_COMMENTS.REQUEST,
    postId,
  };
}

export function loadWpComments(postId) {
  return {
    type: LOAD_WP_COMMENTS.FETCH,
    postId,
  };
}

export function loadWpCommentsSuccess(postId, comments) {
  console.log('Success! Comments:');
  console.log(comments);
  return {
    type: LOAD_WP_COMMENTS.SUCCESS,
    postId,
    comments,
  };
}

export function loadWpCommentsFail(postId, error) {
  return {
    type: LOAD_WP_COMMENTS.FAILURE,
    postId,
    error,
  };
}
