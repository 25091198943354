import { createAsyncAction } from '../../utils/actionUtils';

export const LOAD_WP_POSTS = createAsyncAction('LOAD_WP_POSTS');

export function requestWpPosts(name, page = 1, options = {}) {
  return {
    type: LOAD_WP_POSTS.REQUEST,
    name,
    page,
    options,
  };
}

export function loadWpPosts(name, page = 1) {
  return {
    type: LOAD_WP_POSTS.FETCH,
    name,
    page,
  };
}

export function loadWpPostsSuccess(name, paging, posts) {
  return {
    type: LOAD_WP_POSTS.SUCCESS,
    name,
    paging,
    posts,
  };
}

export function loadWpPostsFail(name, error) {
  return {
    type: LOAD_WP_POSTS.FAILURE,
    name,
    error,
  };
}
