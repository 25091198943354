import { createAsyncAction } from '../../utils/actionUtils';

export const LOAD_WP_POST = createAsyncAction('LOAD_WP_POST');
export const LOAD_RELATED_POST = createAsyncAction('LOAD_RELATED_POST');

export function requestWpPost(postId) {
  return {
    type: LOAD_WP_POST.REQUEST,
    postId,
  };
}

export function loadWpPost(postId) {
  return {
    type: LOAD_WP_POST.FETCH,
    postId,
  };
}

export function loadWpPostSuccess(postId, post) {
  return {
    type: LOAD_WP_POST.SUCCESS,
    postId,
    post,
  };
}

export function loadWpPostFail(postId, error) {
  return {
    type: LOAD_WP_POST.FAILURE,
    postId,
    error,
  };
}

export function requestRelatedPost(post) {
  return {
    type: LOAD_RELATED_POST.REQUEST,
    post,
  };
}

export function loadRelatedPost(postId) {
  return {
    type: LOAD_RELATED_POST.FETCH,
    postId,
  };
}

export function loadRelatedPostSuccess(postId, posts) {
  return {
    type: LOAD_RELATED_POST.SUCCESS,
    postId,
    posts,
  };
}

export function loadRelatedPostFail(postId, error) {
  return {
    type: LOAD_RELATED_POST.FAILURE,
    postId,
    error,
  };
}
